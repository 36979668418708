import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { ChevronRight } from '../custom-icons';

const getBgClass = (theme) => {
  switch (theme) {
    case 'primary':
      return 'bg-primary-4';
    case 'neutral':
      return 'bg-neutral';
    case 'secondary':
      return 'bg-secondary-4';
    case 'brown':
      return 'bg-brown-4';
    default:
      return '';
  }
};

function HeroSection({ data }) {
  return (
    <div className="relative">
      <Link
        to={data.link.url}
        className="absolute top-0 bottom-0 left-0 right-0 cursor-pointer"
        style={{ zIndex: 3 }}
      ></Link>
      <div
        className={`w-full ${getBgClass(data.theme)}`}
        style={{ minHeight: 278 }}
      >
        <div>
          <div
            className={`mx-4 mb-4 pt-10 md:pt-6 text-center font-extraBold text-3xl md:text-5xl leading-tight tracking-tighter md:tracking-tight text-${
              data.textColor || 'accent'
            }`}
          >
            {data.title}
          </div>
          <div
            className={`font-regular text-center text-xl md:text-3xl px-0 lg:px-40 text-${
              data.textColor || 'accent'
            }`}
          >
            {data.subTitle}
          </div>
          <div
            className={`my-8 text-center font-semiBold ${
              data.theme !== 'neutral' ? 'text-neutral' : ''
            }`}
          >
            <Link
              to={data.link.url}
              className="relative inline-flex"
              style={{ zIndex: 4 }}
            >
              <span
                className={
                  data.theme === 'neutral'
                    ? 'link-underline-primary'
                    : 'link-underline-white'
                }
              >
                {data.link.text}
              </span>
              {data.theme === 'neutral' ? (
                <ChevronRight className="text-secondary" />
              ) : (
                <ChevronRight />
              )}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

HeroSection.propTypes = {
  data: PropTypes.object.isRequired,
};

export default HeroSection;
